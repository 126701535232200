import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dialog-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    title: _ctx.title,
    "close-on-click-modal": false,
    "align-center": true,
    width: _ctx.width,
    top: _ctx.top,
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.dialogVisible = $event)),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('close')))
  }, {
    footer: _withCtx(() => [
      (_ctx.footerType === 'default')
        ? (_openBlock(), _createBlock("span", _hoisted_1, [
            _createVNode(_component_el_button, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootCancel), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_button, {
              type: "primary",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('submit')))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$wordList.TabelFootSubmit), 1)
              ]),
              _: 1
            })
          ]))
        : _renderSlot(_ctx.$slots, "footer", { key: 1 })
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["title", "width", "top", "modelValue"]))
}