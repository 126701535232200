interface TimeZoneInterface {
    label: string;
    value: string;
}
const defaultTimeValue = '+0:00 GMT';

const getManageDefaultTimeZone = () => (localStorage.getItem('manageTimeZone') === 'null' || localStorage.getItem('manageTimeZone') === null
    ? defaultTimeValue : (localStorage.getItem('manageTimeZone') as string));

const timeZoneOptions: Array<TimeZoneInterface> = [
    {
        label: 'GMT-11:00 Midway',
        value: '-11:00 Midway'
    },
    {
        label: 'GMT-11:00 Niue',
        value: '-11:00 Niue'
    },
    {
        label: 'GMT-11:00 Pago_Pago',
        value: '-11:00 Pago_Pago'
    },
    {
        label: 'GMT-10:00 Adak',
        value: '-10:00 Adak'
    },
    {
        label: 'GMT-10:00 Honolulu',
        value: '-10:00 Honolulu'
    },
    {
        label: 'GMT-10:00 Johnston',
        value: '-10:00 Johnston'
    },
    {
        label: 'GMT-10:00 Rarotonga',
        value: '-10:00 Rarotonga'
    },
    {
        label: 'GMT-10:00 Tahiti',
        value: '-10:00 Tahiti'
    },
    {
        label: 'GMT-9:30 Marquesas',
        value: '-9:30 Marquesas'
    },
    {
        label: 'GMT-9:00 Anchorage',
        value: '-9:00 Anchorage'
    },
    {
        label: 'GMT-9:00 Juneau',
        value: '-9:00 Juneau'
    },
    {
        label: 'GMT-9:00 Metlakatla',
        value: '-9:00 Metlakatla'
    },
    {
        label: 'GMT-9:00 Nome',
        value: '-9:00 Nome'
    },
    {
        label: 'GMT-9:00 Sitka',
        value: '-9:00 Sitka'
    },
    {
        label: 'GMT-9:00 Yakutat',
        value: '-9:00 Yakutat'
    },
    {
        label: 'GMT-9:00 Gambier',
        value: '-9:00 Gambier'
    },
    {
        label: 'GMT-8:00 Los_Angeles',
        value: '-8:00 Los_Angeles'
    },
    {
        label: 'GMT-8:00 Santa_Isabel',
        value: '-8:00 Santa_Isabel'
    },
    {
        label: 'GMT-8:00 Tijuana',
        value: '-8:00 Tijuana'
    },
    {
        label: 'GMT-8:00 Vancouver',
        value: '-8:00 Vancouver'
    },
    {
        label: 'GMT-8:00 Pitcairn',
        value: '-8:00 Pitcairn'
    },
    {
        label: 'GMT-7:00 Boise',
        value: '-7:00 Boise'
    },
    {
        label: 'GMT-7:00 Cambridge_Bay',
        value: '-7:00 Cambridge_Bay'
    },
    {
        label: 'GMT-7:00 Creston',
        value: '-7:00 Creston'
    },
    {
        label: 'GMT-7:00 Dawson',
        value: '-7:00 Dawson'
    },
    {
        label: 'GMT-7:00 Dawson_Creek',
        value: '-7:00 Dawson_Creek'
    },
    {
        label: 'GMT-7:00 Denver',
        value: '-7:00 Denver'
    },
    {
        label: 'GMT-7:00 Edmonton',
        value: '-7:00 Edmonton'
    },
    {
        label: 'GMT-7:00 Fort_Nelson',
        value: '-7:00 Fort_Nelson'
    },
    {
        label: 'GMT-7:00 Hermosillo',
        value: '-7:00 Hermosillo'
    },
    {
        label: 'GMT-7:00 Inuvik',
        value: '-7:00 Inuvik'
    },
    {
        label: 'GMT-7:00 Mazatlan',
        value: '-7:00 Mazatlan'
    },
    {
        label: 'GMT-7:00 Phoenix',
        value: '-7:00 Phoenix'
    },
    {
        label: 'GMT-7:00 Shiprock',
        value: '-7:00 Shiprock'
    },
    {
        label: 'GMT-7:00 Whitehorse',
        value: '-7:00 Whitehorse'
    },
    {
        label: 'GMT-7:00 Yellowknife',
        value: '-7:00 Yellowknife'
    },
    {
        label: 'GMT-6:00 Ojinaga',
        value: '-6:00 Ojinaga'
    },
    {
        label: 'GMT-6:00 Chihuahua',
        value: '-6:00 Chihuahua'
    },
    {
        label: 'GMT-6:00 Bahia_Banderas',
        value: '-6:00 Bahia_Banderas'
    },
    {
        label: 'GMT-6:00 Belize',
        value: '-6:00 Belize'
    },
    {
        label: 'GMT-6:00 Chicago',
        value: '-6:00 Chicago'
    },
    {
        label: 'GMT-6:00 Costa_Rica',
        value: '-6:00 Costa_Rica'
    },
    {
        label: 'GMT-6:00 El_Salvador',
        value: '-6:00 El_Salvador'
    },
    {
        label: 'GMT-6:00 Guatemala',
        value: '-6:00 Guatemala'
    },
    {
        label: 'GMT-6:00 Knox',
        value: '-6:00 Knox'
    },
    {
        label: 'GMT-6:00 Tell_City',
        value: '-6:00 Tell_City'
    },
    {
        label: 'GMT-6:00 Managua',
        value: '-6:00 Managua'
    },
    {
        label: 'GMT-6:00 Matamoros',
        value: '-6:00 Matamoros'
    },
    {
        label: 'GMT-6:00 Menominee',
        value: '-6:00 Menominee'
    },
    {
        label: 'GMT-6:00 Merida',
        value: '-6:00 Merida'
    },
    {
        label: 'GMT-6:00 Mexico_City',
        value: '-6:00 Mexico_City'
    },
    {
        label: 'GMT-6:00 Monterrey',
        value: '-6:00 Monterrey'
    },
    {
        label: 'GMT-6:00 North_Dakota/Beulah',
        value: '-6:00 North_Dakota/Beulah'
    },
    {
        label: 'GMT-6:00 North_Dakota/Center',
        value: '-6:00 North_Dakota/Center'
    },
    {
        label: 'GMT-6:00 North_Dakota/New_Salem',
        value: '-6:00 North_Dakota/New_Salem'
    },
    {
        label: 'GMT-6:00 Rainy_River',
        value: '-6:00 Rainy_River'
    },
    {
        label: 'GMT-6:00 Rankin_Inlet',
        value: '-6:00 Rankin_Inlet'
    },
    {
        label: 'GMT-6:00 Regina',
        value: '-6:00 Regina'
    },
    {
        label: 'GMT-6:00 Resolute',
        value: '-6:00 Resolute'
    },
    {
        label: 'GMT-6:00 Swift_Current',
        value: '-6:00 Swift_Current'
    },
    {
        label: 'GMT-6:00 Tegucigalpa',
        value: '-6:00 Tegucigalpa'
    },
    {
        label: 'GMT-6:00 Winnipeg',
        value: '-6:00 Winnipeg'
    },
    {
        label: 'GMT-6:00 Easter',
        value: '-6:00 Easter'
    },
    {
        label: 'GMT-6:00 Galapagos',
        value: '-6:00 Galapagos'
    },
    {
        label: 'GMT-5:00 Atikokan',
        value: '-5:00 Atikokan'
    },
    {
        label: 'GMT-5:00 Bogota',
        value: '-5:00 Bogota'
    },
    {
        label: 'GMT-5:00 Cancun',
        value: '-5:00 Cancun'
    },
    {
        label: 'GMT-5:00 Cayman',
        value: '-5:00 Cayman'
    },
    {
        label: 'GMT-5:00 Detroit',
        value: '-5:00 Detroit'
    },
    {
        label: 'GMT-5:00 Eirunepe',
        value: '-5:00 Eirunepe'
    },
    {
        label: 'GMT-5:00 Grand_Turk',
        value: '-5:00 Grand_Turk'
    },
    {
        label: 'GMT-5:00 Guayaquil',
        value: '-5:00 Guayaquil'
    },
    {
        label: 'GMT-5:00 Havana',
        value: '-5:00 Havana'
    },
    {
        label: 'GMT-5:00 Indianapolis',
        value: '-5:00 Indianapolis'
    },
    {
        label: 'GMT-5:00 Marengo',
        value: '-5:00 Marengo'
    },
    {
        label: 'GMT-5:00 Petersburg',
        value: '-5:00 Petersburg'
    },
    {
        label: 'GMT-5:00 Vevay',
        value: '-5:00 Vevay'
    },
    {
        label: 'GMT-5:00 Vincennes',
        value: '-5:00 Vincennes'
    },
    {
        label: 'GMT-5:00 Winamac',
        value: '-5:00 Winamac'
    },
    {
        label: 'GMT-5:00 Iqaluit',
        value: '-5:00 Iqaluit'
    },
    {
        label: 'GMT-5:00 Jamaica',
        value: '-5:00 Jamaica'
    },
    {
        label: 'GMT-5:00 Louisville',
        value: '-5:00 Louisville'
    },
    {
        label: 'GMT-5:00 Monticello',
        value: '-5:00 Monticello'
    },
    {
        label: 'GMT-5:00 Lima',
        value: '-5:00 Lima'
    },
    {
        label: 'GMT-5:00 Montreal',
        value: '-5:00 Montreal'
    },
    {
        label: 'GMT-5:00 Nassau',
        value: '-5:00 Nassau'
    },
    {
        label: 'GMT-5:00 New_York',
        value: '-5:00 New_York'
    },
    {
        label: 'GMT-5:00 Nipigon',
        value: '-5:00 Nipigon'
    },
    {
        label: 'GMT-5:00 Panama',
        value: '-5:00 Panama'
    },
    {
        label: 'GMT-5:00 Pangnirtung',
        value: '-5:00 Pangnirtung'
    },
    {
        label: 'GMT-5:00 Port-au-Prince',
        value: '-5:00 Port-au-Prince'
    },
    {
        label: 'GMT-5:00 Rio_Branco',
        value: '-5:00 Rio_Branco'
    },
    {
        label: 'GMT-5:00 Thunder_Bay',
        value: '-5:00 Thunder_Bay'
    },
    {
        label: 'GMT-5:00 Toronto',
        value: '-5:00 Toronto'
    },
    {
        label: 'GMT-4:00 Anguilla',
        value: '-4:00 Anguilla'
    },
    {
        label: 'GMT-4:00 Antigua',
        value: '-4:00 Antigua'
    },
    {
        label: 'GMT-4:00 Aruba',
        value: '-4:00 Aruba'
    },
    {
        label: 'GMT-4:00 Barbados',
        value: '-4:00 Barbados'
    },
    {
        label: 'GMT-4:00 Blanc-Sablon',
        value: '-4:00 Blanc-Sablon'
    },
    {
        label: 'GMT-4:00 Boa_Vista',
        value: '-4:00 Boa_Vista'
    },
    {
        label: 'GMT-4:00 Campo_Grande',
        value: '-4:00 Campo_Grande'
    },
    {
        label: 'GMT-4:00 Caracas',
        value: '-4:00 Caracas'
    },
    {
        label: 'GMT-4:00 Cuiaba',
        value: '-4:00 Cuiaba'
    },
    {
        label: 'GMT-4:00 Curacao',
        value: '-4:00 Curacao'
    },
    {
        label: 'GMT-4:00 Dominica',
        value: '-4:00 Dominica'
    },
    {
        label: 'GMT-4:00 Glace_Bay',
        value: '-4:00 Glace_Bay'
    },
    {
        label: 'GMT-4:00 Goose_Bay',
        value: '-4:00 Goose_Bay'
    },
    {
        label: 'GMT-4:00 Grenada',
        value: '-4:00 Grenada'
    },
    {
        label: 'GMT-4:00 Guadeloupe',
        value: '-4:00 Guadeloupe'
    },
    {
        label: 'GMT-4:00 Guyana',
        value: '-4:00 Guyana'
    },
    {
        label: 'GMT-4:00 Halifax',
        value: '-4:00 Halifax'
    },
    {
        label: 'GMT-4:00 Kralendijk',
        value: '-4:00 Kralendijk'
    },
    {
        label: 'GMT-4:00 La_Paz',
        value: '-4:00 La_Paz'
    },
    {
        label: 'GMT-4:00 Lower_Princes',
        value: '-4:00 Lower_Princes'
    },
    {
        label: 'GMT-4:00 Manaus',
        value: '-4:00 Manaus'
    },
    {
        label: 'GMT-4:00 Marigot',
        value: '-4:00 Marigot'
    },
    {
        label: 'GMT-4:00 Martinique',
        value: '-4:00 Martinique'
    },
    {
        label: 'GMT-4:00 Moncton',
        value: '-4:00 Moncton'
    },
    {
        label: 'GMT-4:00 Montserrat',
        value: '-4:00 Montserrat'
    },
    {
        label: 'GMT-4:00 Port_of_Spain',
        value: '-4:00 Port_of_Spain'
    },
    {
        label: 'GMT-4:00 Porto_Velho',
        value: '-4:00 Porto_Velho'
    },
    {
        label: 'GMT-4:00 Puerto_Rico',
        value: '-4:00 Puerto_Rico'
    },
    {
        label: 'GMT-4:00 Santiago',
        value: '-4:00 Santiago'
    },
    {
        label: 'GMT-4:00 Santo_Domingo',
        value: '-4:00 Santo_Domingo'
    },
    {
        label: 'GMT-4:00 St_Barthelemy',
        value: '-4:00 St_Barthelemy'
    },
    {
        label: 'GMT-4:00 St_Kitts',
        value: '-4:00 St_Kitts'
    },
    {
        label: 'GMT-4:00 St_Lucia',
        value: '-4:00 St_Lucia'
    },
    {
        label: 'GMT-4:00 St_Thomas',
        value: '-4:00 St_Thomas'
    },
    {
        label: 'GMT-4:00 St_Vincent',
        value: '-4:00 St_Vincent'
    },
    {
        label: 'GMT-4:00 Thule',
        value: '-4:00 Thule'
    },
    {
        label: 'GMT-4:00 Tortola',
        value: '-4:00 Tortola'
    },
    {
        label: 'GMT-4:00 Bermuda',
        value: '-4:00 Bermuda'
    },
    {
        label: 'GMT-3:30 St_Johns',
        value: '-3:30 St_Johns'
    },
    {
        label: 'GMT-3:00 Araguaina',
        value: '-3:00 Araguaina'
    },
    {
        label: 'GMT-3:00 Asuncion',
        value: '-3:00 Asuncion'
    },
    {
        label: 'GMT-3:00 Buenos_Aires',
        value: '-3:00 Buenos_Aires'
    },
    {
        label: 'GMT-3:00 Catamarca',
        value: '-3:00 Catamarca'
    },
    {
        label: 'GMT-3:00 Cordoba',
        value: '-3:00 Cordoba'
    },
    {
        label: 'GMT-3:00 Jujuy',
        value: '-3:00 Jujuy'
    },
    {
        label: 'GMT-3:00 La_Rioja',
        value: '-3:00 La_Rioja'
    },
    {
        label: 'GMT-3:00 Mendoza',
        value: '-3:00 Mendoza'
    },
    {
        label: 'GMT-3:00 Rio_Gallegos',
        value: '-3:00 Rio_Gallegos'
    },
    {
        label: 'GMT-3:00 Salta',
        value: '-3:00 Salta'
    },
    {
        label: 'GMT-3:00 San_Juan',
        value: '-3:00 San_Juan'
    },
    {
        label: 'GMT-3:00 San_Luis',
        value: '-3:00 San_Luis'
    },
    {
        label: 'GMT-3:00 Tucuman',
        value: '-3:00 Tucuman'
    },
    {
        label: 'GMT-3:00 Ushuaia',
        value: '-3:00 Ushuaia'
    },
    {
        label: 'GMT-3:00 Bahia',
        value: '-3:00 Bahia'
    },
    {
        label: 'GMT-3:00 Belem',
        value: '-3:00 Belem'
    },
    {
        label: 'GMT-3:00 Cayenne',
        value: '-3:00 Cayenne'
    },
    {
        label: 'GMT-3:00 Fortaleza',
        value: '-3:00 Fortaleza'
    },
    {
        label: 'GMT-3:00 Maceio',
        value: '-3:00 Maceio'
    },
    {
        label: 'GMT-3:00 Miquelon',
        value: '-3:00 Miquelon'
    },
    {
        label: 'GMT-3:00 Montevideo',
        value: '-3:00 Montevideo'
    },
    {
        label: 'GMT-3:00 Paramaribo',
        value: '-3:00 Paramaribo'
    },
    {
        label: 'GMT-3:00 Punta_Arenas',
        value: '-3:00 Punta_Arenas'
    },
    {
        label: 'GMT-3:00 Recife',
        value: '-3:00 Recife'
    },
    {
        label: 'GMT-3:00 Santarem',
        value: '-3:00 Santarem'
    },
    {
        label: 'GMT-3:00 Sao_Paulo',
        value: '-3:00 Sao_Paulo'
    },
    {
        label: 'GMT-3:00 Palmer',
        value: '-3:00 Palmer'
    },
    {
        label: 'GMT-3:00 Rothera',
        value: '-3:00 Rothera'
    },
    {
        label: 'GMT-3:00 Stanley',
        value: '-3:00 Stanley'
    },
    {
        label: 'GMT-2:00 Nuuk',
        value: '-2:00 Nuuk'
    },
    {
        label: 'GMT-2:00 Godthab',
        value: '-2:00 Godthab'
    },
    {
        label: 'GMT-2:00 Noronha',
        value: '-2:00 Noronha'
    },
    {
        label: 'GMT-2:00 South_Georgia',
        value: '-2:00 South_Georgia'
    },
    {
        label: 'GMT-2:00 Scoresbysund',
        value: '-2:00 Scoresbysund'
    },
    {
        label: 'GMT-1:00 Azores',
        value: '-1:00 Azores'
    },
    {
        label: 'GMT-1:00 Cape_Verde',
        value: '-1:00 Cape_Verde'
    },
    {
        label: 'GMT+0:00 Abidjan',
        value: '+0:00 Abidjan'
    },
    {
        label: 'GMT+0:00 Accra',
        value: '+0:00 Accra'
    },
    {
        label: 'GMT+0:00 Bamako',
        value: '+0:00 Bamako'
    },
    {
        label: 'GMT+0:00 Banjul',
        value: '+0:00 Banjul'
    },
    {
        label: 'GMT+0:00 Bissau',
        value: '+0:00 Bissau'
    },
    {
        label: 'GMT+0:00 Conakry',
        value: '+0:00 Conakry'
    },
    {
        label: 'GMT+0:00 Dakar',
        value: '+0:00 Dakar'
    },
    {
        label: 'GMT+0:00 Freetown',
        value: '+0:00 Freetown'
    },
    {
        label: 'GMT+0:00 Lome',
        value: '+0:00 Lome'
    },
    {
        label: 'GMT+0:00 Monrovia',
        value: '+0:00 Monrovia'
    },
    {
        label: 'GMT+0:00 Nouakchott',
        value: '+0:00 Nouakchott'
    },
    {
        label: 'GMT+0:00 Ouagadougou',
        value: '+0:00 Ouagadougou'
    },
    {
        label: 'GMT+0:00 Sao_Tome',
        value: '+0:00 Sao_Tome'
    },
    {
        label: 'GMT+0:00 Danmarkshavn',
        value: '+0:00 Danmarkshavn'
    },
    {
        label: 'GMT+0:00 Troll',
        value: '+0:00 Troll'
    },
    {
        label: 'GMT+0:00 Canary',
        value: '+0:00 Canary'
    },
    {
        label: 'GMT+0:00 Faroe',
        value: '+0:00 Faroe'
    },
    {
        label: 'GMT+0:00 Madeira',
        value: '+0:00 Madeira'
    },
    {
        label: 'GMT+0:00 Reykjavik',
        value: '+0:00 Reykjavik'
    },
    {
        label: 'GMT+0:00 St_Helena',
        value: '+0:00 St_Helena'
    },
    {
        label: 'GMT+0:00 Guernsey',
        value: '+0:00 Guernsey'
    },
    {
        label: 'GMT+0:00 Isle_of_Man',
        value: '+0:00 Isle_of_Man'
    },
    {
        label: 'GMT+0:00 Jersey',
        value: '+0:00 Jersey'
    },
    {
        label: 'GMT+0:00 Lisbon',
        value: '+0:00 Lisbon'
    },
    {
        label: 'GMT+0:00 London',
        value: '+0:00 London'
    },
    {
        label: 'GMT+0:00 GMT',
        value: '+0:00 GMT'
    },
    {
        label: 'GMT+1:00 Algiers',
        value: '+1:00 Algiers'
    },
    {
        label: 'GMT+1:00 Bangui',
        value: '+1:00 Bangui'
    },
    {
        label: 'GMT+1:00 Brazzaville',
        value: '+1:00 Brazzaville'
    },
    {
        label: 'GMT+1:00 Casablanca',
        value: '+1:00 Casablanca'
    },
    {
        label: 'GMT+1:00 Ceuta',
        value: '+1:00 Ceuta'
    },
    {
        label: 'GMT+1:00 Douala',
        value: '+1:00 Douala'
    },
    {
        label: 'GMT+1:00 El_Aaiun',
        value: '+1:00 El_Aaiun'
    },
    {
        label: 'GMT+1:00 Kinshasa',
        value: '+1:00 Kinshasa'
    },
    {
        label: 'GMT+1:00 Lagos',
        value: '+1:00 Lagos'
    },
    {
        label: 'GMT+1:00 Libreville',
        value: '+1:00 Libreville'
    },
    {
        label: 'GMT+1:00 Luanda',
        value: '+1:00 Luanda'
    },
    {
        label: 'GMT+1:00 Malabo',
        value: '+1:00 Malabo'
    },
    {
        label: 'GMT+1:00 Ndjamena',
        value: '+1:00 Ndjamena'
    },
    {
        label: 'GMT+1:00 Niamey',
        value: '+1:00 Niamey'
    },
    {
        label: 'GMT+1:00 Porto-Novo',
        value: '+1:00 Porto-Novo'
    },
    {
        label: 'GMT+1:00 Tunis',
        value: '+1:00 Tunis'
    },
    {
        label: 'GMT+1:00 Longyearbyen',
        value: '+1:00 Longyearbyen'
    },
    {
        label: 'GMT+1:00 Amsterdam',
        value: '+1:00 Amsterdam'
    },
    {
        label: 'GMT+1:00 Andorra',
        value: '+1:00 Andorra'
    },
    {
        label: 'GMT+1:00 Belgrade',
        value: '+1:00 Belgrade'
    },
    {
        label: 'GMT+1:00 Berlin',
        value: '+1:00 Berlin'
    },
    {
        label: 'GMT+1:00 Bratislava',
        value: '+1:00 Bratislava'
    },
    {
        label: 'GMT+1:00 Brussels',
        value: '+1:00 Brussels'
    },
    {
        label: 'GMT+1:00 Budapest',
        value: '+1:00 Budapest'
    },
    {
        label: 'GMT+1:00 Busingen',
        value: '+1:00 Busingen'
    },
    {
        label: 'GMT+1:00 Copenhagen',
        value: '+1:00 Copenhagen'
    },
    {
        label: 'GMT+1:00 Dublin',
        value: '+1:00 Dublin'
    },
    {
        label: 'GMT+1:00 Gibraltar',
        value: '+1:00 Gibraltar'
    },
    {
        label: 'GMT+1:00 Ljubljana',
        value: '+1:00 Ljubljana'
    },
    {
        label: 'GMT+1:00 Luxembourg',
        value: '+1:00 Luxembourg'
    },
    {
        label: 'GMT+1:00 Madrid',
        value: '+1:00 Madrid'
    },
    {
        label: 'GMT+1:00 Malta',
        value: '+1:00 Malta'
    },
    {
        label: 'GMT+1:00 Monaco',
        value: '+1:00 Monaco'
    },
    {
        label: 'GMT+1:00 Oslo',
        value: '+1:00 Oslo'
    },
    {
        label: 'GMT+1:00 Paris',
        value: '+1:00 Paris'
    },
    {
        label: 'GMT+1:00 Podgorica',
        value: '+1:00 Podgorica'
    },
    {
        label: 'GMT+1:00 Prague',
        value: '+1:00 Prague'
    },
    {
        label: 'GMT+1:00 Rome',
        value: '+1:00 Rome'
    },
    {
        label: 'GMT+1:00 San_Marino',
        value: '+1:00 San_Marino'
    },
    {
        label: 'GMT+1:00 Sarajevo',
        value: '+1:00 Sarajevo'
    },
    {
        label: 'GMT+1:00 Skopje',
        value: '+1:00 Skopje'
    },
    {
        label: 'GMT+1:00 Stockholm',
        value: '+1:00 Stockholm'
    },
    {
        label: 'GMT+1:00 Tirane',
        value: '+1:00 Tirane'
    },
    {
        label: 'GMT+1:00 Vaduz',
        value: '+1:00 Vaduz'
    },
    {
        label: 'GMT+1:00 Vatican',
        value: '+1:00 Vatican'
    },
    {
        label: 'GMT+1:00 Vienna',
        value: '+1:00 Vienna'
    },
    {
        label: 'GMT+1:00 Warsaw',
        value: '+1:00 Warsaw'
    },
    {
        label: 'GMT+1:00 Zagreb',
        value: '+1:00 Zagreb'
    },
    {
        label: 'GMT+1:00 Zurich',
        value: '+1:00 Zurich'
    },
    {
        label: 'GMT+2:00 Blantyre',
        value: '+2:00 Blantyre'
    },
    {
        label: 'GMT+2:00 Bujumbura',
        value: '+2:00 Bujumbura'
    },
    {
        label: 'GMT+2:00 Cairo',
        value: '+2:00 Cairo'
    },
    {
        label: 'GMT+2:00 Gaborone',
        value: '+2:00 Gaborone'
    },
    {
        label: 'GMT+2:00 Harare',
        value: '+2:00 Harare'
    },
    {
        label: 'GMT+2:00 Johannesburg',
        value: '+2:00 Johannesburg'
    },
    {
        label: 'GMT+2:00 Khartoum',
        value: '+2:00 Khartoum'
    },
    {
        label: 'GMT+2:00 Kigali',
        value: '+2:00 Kigali'
    },
    {
        label: 'GMT+2:00 Lubumbashi',
        value: '+2:00 Lubumbashi'
    },
    {
        label: 'GMT+2:00 Lusaka',
        value: '+2:00 Lusaka'
    },
    {
        label: 'GMT+2:00 Maputo',
        value: '+2:00 Maputo'
    },
    {
        label: 'GMT+2:00 Maseru',
        value: '+2:00 Maseru'
    },
    {
        label: 'GMT+2:00 Mbabane',
        value: '+2:00 Mbabane'
    },
    {
        label: 'GMT+2:00 Tripoli',
        value: '+2:00 Tripoli'
    },
    {
        label: 'GMT+2:00 Windhoek',
        value: '+2:00 Windhoek'
    },
    {
        label: 'GMT+2:00 Beirut',
        value: '+2:00 Beirut'
    },
    {
        label: 'GMT+2:00 Famagusta',
        value: '+2:00 Famagusta'
    },
    {
        label: 'GMT+2:00 Gaza',
        value: '+2:00 Gaza'
    },
    {
        label: 'GMT+2:00 Hebron',
        value: '+2:00 Hebron'
    },
    {
        label: 'GMT+2:00 Jerusalem',
        value: '+2:00 Jerusalem'
    },
    {
        label: 'GMT+2:00 Athens',
        value: '+2:00 Athens'
    },
    {
        label: 'GMT+2:00 Bucharest',
        value: '+2:00 Bucharest'
    },
    {
        label: 'GMT+2:00 Chisinau',
        value: '+2:00 Chisinau'
    },
    {
        label: 'GMT+2:00 Helsinki',
        value: '+2:00 Helsinki'
    },
    {
        label: 'GMT+2:00 Kaliningrad',
        value: '+2:00 Kaliningrad'
    },
    {
        label: 'GMT+2:00 Kiev',
        value: '+2:00 Kiev'
    },
    {
        label: 'GMT+2:00 Mariehamn',
        value: '+2:00 Mariehamn'
    },
    {
        label: 'GMT+2:00 Nicosia',
        value: '+2:00 Nicosia'
    },
    {
        label: 'GMT+2:00 Riga',
        value: '+2:00 Riga'
    },
    {
        label: 'GMT+2:00 Sofia',
        value: '+2:00 Sofia'
    },
    {
        label: 'GMT+2:00 Tallinn',
        value: '+2:00 Tallinn'
    },
    {
        label: 'GMT+2:00 Uzhgorod',
        value: '+2:00 Uzhgorod'
    },
    {
        label: 'GMT+2:00 Vilnius',
        value: '+2:00 Vilnius'
    },
    {
        label: 'GMT+2:00 Zaporozhye',
        value: '+2:00 Zaporozhye'
    },
    {
        label: 'GMT+2:00 Juba',
        value: '+2:00 Juba'
    },
    {
        label: 'GMT+3:00 Addis_Ababa',
        value: '+3:00 Addis_Ababa'
    },
    {
        label: 'GMT+3:00 Asmara',
        value: '+3:00 Asmara'
    },
    {
        label: 'GMT+3:00 Dar_es_Salaam',
        value: '+3:00 Dar_es_Salaam'
    },
    {
        label: 'GMT+3:00 Djibouti',
        value: '+3:00 Djibouti'
    },
    {
        label: 'GMT+3:00 Kampala',
        value: '+3:00 Kampala'
    },
    {
        label: 'GMT+3:00 Mogadishu',
        value: '+3:00 Mogadishu'
    },
    {
        label: 'GMT+3:00 Nairobi',
        value: '+3:00 Nairobi'
    },
    {
        label: 'GMT+3:00 Syowa',
        value: '+3:00 Syowa'
    },
    {
        label: 'GMT+3:00 Amman',
        value: '+3:00 Amman'
    },
    {
        label: 'GMT+3:00 Damascus',
        value: '+3:00 Damascus'
    },
    {
        label: 'GMT+3:00 Aden',
        value: '+3:00 Aden'
    },
    {
        label: 'GMT+3:00 Baghdad',
        value: '+3:00 Baghdad'
    },
    {
        label: 'GMT+3:00 Bahrain',
        value: '+3:00 Bahrain'
    },
    {
        label: 'GMT+3:00 Kuwait',
        value: '+3:00 Kuwait'
    },
    {
        label: 'GMT+3:00 Qatar',
        value: '+3:00 Qatar'
    },
    {
        label: 'GMT+3:00 Riyadh',
        value: '+3:00 Riyadh'
    },
    {
        label: 'GMT+3:00 Istanbul',
        value: '+3:00 Istanbul'
    },
    {
        label: 'GMT+3:00 Kirov',
        value: '+3:00 Kirov'
    },
    {
        label: 'GMT+3:00 Minsk',
        value: '+3:00 Minsk'
    },
    {
        label: 'GMT+3:00 Moscow',
        value: '+3:00 Moscow'
    },
    {
        label: 'GMT+3:00 Simferopol',
        value: '+3:00 Simferopol'
    },
    {
        label: 'GMT+3:00 Antananarivo',
        value: '+3:00 Antananarivo'
    },
    {
        label: 'GMT+3:00 Comoro',
        value: '+3:00 Comoro'
    },
    {
        label: 'GMT+3:00 Mayotte',
        value: '+3:00 Mayotte'
    },
    {
        label: 'GMT+3:00 Volgograd',
        value: '+3:00 Volgograd'
    },
    {
        label: 'GMT+3:30 Tehran',
        value: '+3:30 Tehran'
    },
    {
        label: 'GMT+4:00 Baku',
        value: '+4:00 Baku'
    },
    {
        label: 'GMT+4:00 Dubai',
        value: '+4:00 Dubai'
    },
    {
        label: 'GMT+4:00 Muscat',
        value: '+4:00 Muscat'
    },
    {
        label: 'GMT+4:00 Tbilisi',
        value: '+4:00 Tbilisi'
    },
    {
        label: 'GMT+4:00 Yerevan',
        value: '+4:00 Yerevan'
    },
    {
        label: 'GMT+4:00 Astrakhan',
        value: '+4:00 Astrakhan'
    },
    {
        label: 'GMT+4:00 Samara',
        value: '+4:00 Samara'
    },
    {
        label: 'GMT+4:00 Saratov',
        value: '+4:00 Saratov'
    },
    {
        label: 'GMT+4:00 Ulyanovsk',
        value: '+4:00 Ulyanovsk'
    },
    {
        label: 'GMT+4:00 Mahe',
        value: '+4:00 Mahe'
    },
    {
        label: 'GMT+4:00 Mauritius',
        value: '+4:00 Mauritius'
    },
    {
        label: 'GMT+4:00 Reunion',
        value: '+4:00 Reunion'
    },
    {
        label: 'GMT+4:30 Kabul',
        value: '+4:30 Kabul'
    },
    {
        label: 'GMT+5:00 Mawson',
        value: '+5:00 Mawson'
    },
    {
        label: 'GMT+5:00 Aqtau',
        value: '+5:00 Aqtau'
    },
    {
        label: 'GMT+5:00 Aqtobe',
        value: '+5:00 Aqtobe'
    },
    {
        label: 'GMT+5:00 Ashgabat',
        value: '+5:00 Ashgabat'
    },
    {
        label: 'GMT+5:00 Atyrau',
        value: '+5:00 Atyrau'
    },
    {
        label: 'GMT+5:00 Dushanbe',
        value: '+5:00 Dushanbe'
    },
    {
        label: 'GMT+5:00 Karachi',
        value: '+5:00 Karachi'
    },
    {
        label: 'GMT+5:00 Oral',
        value: '+5:00 Oral'
    },
    {
        label: 'GMT+5:00 Qyzylorda',
        value: '+5:00 Qyzylorda'
    },
    {
        label: 'GMT+5:00 Samarkand',
        value: '+5:00 Samarkand'
    },
    {
        label: 'GMT+5:00 Tashkent',
        value: '+5:00 Tashkent'
    },
    {
        label: 'GMT+5:00 Yekaterinburg',
        value: '+5:00 Yekaterinburg'
    },
    {
        label: 'GMT+5:00 Kerguelen',
        value: '+5:00 Kerguelen'
    },
    {
        label: 'GMT+5:00 Maldives',
        value: '+5:00 Maldives'
    },
    {
        label: 'GMT+5:00 Vostok',
        value: '+5:00 Vostok'
    },
    {
        label: 'GMT+5:00 Almaty',
        value: '+5:00 Almaty'
    },
    {
        label: 'GMT+5:00 Qostanay',
        value: '+5:00 Qostanay'
    },
    {
        label: 'GMT+5:30 Calcutta',
        value: '+5:30 Calcutta'
    },
    {
        label: 'GMT+5:30 Colombo',
        value: '+5:30 Colombo'
    },
    {
        label: 'GMT+5:30 Kolkata',
        value: '+5:30 Kolkata'
    },
    {
        label: 'GMT+5:45 Kathmandu',
        value: '+5:45 Kathmandu'
    },
    {
        label: 'GMT+5:45 Katmandu',
        value: '+5:45 Katmandu'
    },
    {
        label: 'GMT+6:00 Bishkek',
        value: '+6:00 Bishkek'
    },
    {
        label: 'GMT+6:00 Dhaka',
        value: '+6:00 Dhaka'
    },
    {
        label: 'GMT+6:00 Kashgar',
        value: '+6:00 Kashgar'
    },
    {
        label: 'GMT+6:00 Omsk',
        value: '+6:00 Omsk'
    },
    {
        label: 'GMT+6:00 Thimphu',
        value: '+6:00 Thimphu'
    },
    {
        label: 'GMT+6:00 Urumqi',
        value: '+6:00 Urumqi'
    },
    {
        label: 'GMT+6:00 Chagos',
        value: '+6:00 Chagos'
    },
    {
        label: 'GMT+6:30 Rangoon',
        value: '+6:30 Rangoon'
    },
    {
        label: 'GMT+6:30 Yangon',
        value: '+6:30 Yangon'
    },
    {
        label: 'GMT+6:30 Cocos',
        value: '+6:30 Cocos'
    },
    {
        label: 'GMT+7:00 Davis',
        value: '+7:00 Davis'
    },
    {
        label: 'GMT+7:00 Bangkok',
        value: '+7:00 Bangkok'
    },
    {
        label: 'GMT+7:00 Barnaul',
        value: '+7:00 Barnaul'
    },
    {
        label: 'GMT+7:00 Ho_Chi_Minh',
        value: '+7:00 Ho_Chi_Minh'
    },
    {
        label: 'GMT+7:00 Hovd',
        value: '+7:00 Hovd'
    },
    {
        label: 'GMT+7:00 Jakarta',
        value: '+7:00 Jakarta'
    },
    {
        label: 'GMT+7:00 Krasnoyarsk',
        value: '+7:00 Krasnoyarsk'
    },
    {
        label: 'GMT+7:00 Novokuznetsk',
        value: '+7:00 Novokuznetsk'
    },
    {
        label: 'GMT+7:00 Novosibirsk',
        value: '+7:00 Novosibirsk'
    },
    {
        label: 'GMT+7:00 Phnom_Penh',
        value: '+7:00 Phnom_Penh'
    },
    {
        label: 'GMT+7:00 Pontianak',
        value: '+7:00 Pontianak'
    },
    {
        label: 'GMT+7:00 Tomsk',
        value: '+7:00 Tomsk'
    },
    {
        label: 'GMT+7:00 Vientiane',
        value: '+7:00 Vientiane'
    },
    {
        label: 'GMT+7:00 Christmas',
        value: '+7:00 Christmas'
    },
    {
        label: 'GMT+8:00 Brunei',
        value: '+8:00 Brunei'
    },
    {
        label: 'GMT+8:00 Choibalsan',
        value: '+8:00 Choibalsan'
    },
    {
        label: 'GMT+8:00 Chongqing',
        value: '+8:00 Chongqing'
    },
    {
        label: 'GMT+8:00 Harbin',
        value: '+8:00 Harbin'
    },
    {
        label: 'GMT+8:00 Hong_Kong',
        value: '+8:00 Hong_Kong'
    },
    {
        label: 'GMT+8:00 Irkutsk',
        value: '+8:00 Irkutsk'
    },
    {
        label: 'GMT+8:00 Kuala_Lumpur',
        value: '+8:00 Kuala_Lumpur'
    },
    {
        label: 'GMT+8:00 Kuching',
        value: '+8:00 Kuching'
    },
    {
        label: 'GMT+8:00 Macau',
        value: '+8:00 Macau'
    },
    {
        label: 'GMT+8:00 Makassar',
        value: '+8:00 Makassar'
    },
    {
        label: 'GMT+8:00 Manila',
        value: '+8:00 Manila'
    },
    {
        label: 'GMT+8:00 Shanghai',
        value: '+8:00 Shanghai'
    },
    {
        label: 'GMT+8:00 Singapore',
        value: '+8:00 Singapore'
    },
    {
        label: 'GMT+8:00 Taipei',
        value: '+8:00 Taipei'
    },
    {
        label: 'GMT+8:00 Ulaanbaatar',
        value: '+8:00 Ulaanbaatar'
    },
    {
        label: 'GMT+8:00 Perth',
        value: '+8:00 Perth'
    },
    {
        label: 'GMT+8:00 Casey',
        value: '+8:00 Casey'
    },
    {
        label: 'GMT+8:45 Eucla',
        value: '+8:45 Eucla'
    },
    {
        label: 'GMT+9:00 Chita',
        value: '+9:00 Chita'
    },
    {
        label: 'GMT+9:00 Dili',
        value: '+9:00 Dili'
    },
    {
        label: 'GMT+9:00 Jayapura',
        value: '+9:00 Jayapura'
    },
    {
        label: 'GMT+9:00 Khandyga',
        value: '+9:00 Khandyga'
    },
    {
        label: 'GMT+9:00 Pyongyang',
        value: '+9:00 Pyongyang'
    },
    {
        label: 'GMT+9:00 Seoul',
        value: '+9:00 Seoul'
    },
    {
        label: 'GMT+9:00 Tokyo',
        value: '+9:00 Tokyo'
    },
    {
        label: 'GMT+9:00 Yakutsk',
        value: '+9:00 Yakutsk'
    },
    {
        label: 'GMT+9:00 Palau',
        value: '+9:00 Palau'
    },
    {
        label: 'GMT+9:30 Adelaide',
        value: '+9:30 Adelaide'
    },
    {
        label: 'GMT+9:30 Broken_Hill',
        value: '+9:30 Broken_Hill'
    },
    {
        label: 'GMT+9:30 Darwin',
        value: '+9:30 Darwin'
    },
    {
        label: 'GMT+10:00 DumontDUrville',
        value: '+10:00 DumontDUrville'
    },
    {
        label: 'GMT+10:00 Ust-Nera',
        value: '+10:00 Ust-Nera'
    },
    {
        label: 'GMT+10:00 Vladivostok',
        value: '+10:00 Vladivostok'
    },
    {
        label: 'GMT+10:00 Brisbane',
        value: '+10:00 Brisbane'
    },
    {
        label: 'GMT+10:00 Currie',
        value: '+10:00 Currie'
    },
    {
        label: 'GMT+10:00 Hobart',
        value: '+10:00 Hobart'
    },
    {
        label: 'GMT+10:00 Lindeman',
        value: '+10:00 Lindeman'
    },
    {
        label: 'GMT+10:00 Melbourne',
        value: '+10:00 Melbourne'
    },
    {
        label: 'GMT+10:00 Sydney',
        value: '+10:00 Sydney'
    },
    {
        label: 'GMT+10:00 Chuuk',
        value: '+10:00 Chuuk'
    },
    {
        label: 'GMT+10:00 Guam',
        value: '+10:00 Guam'
    },
    {
        label: 'GMT+10:00 Port_Moresby',
        value: '+10:00 Port_Moresby'
    },
    {
        label: 'GMT+10:00 Saipan',
        value: '+10:00 Saipan'
    },
    {
        label: 'GMT+10:00 Truk',
        value: '+10:00 Truk'
    },
    {
        label: 'GMT+10:30 Lord_Howe',
        value: '+10:30 Lord_Howe'
    },
    {
        label: 'GMT+10:00 Macquarie',
        value: '+10:00 Macquarie'
    },
    {
        label: 'GMT+11:00 Magadan',
        value: '+11:00 Magadan'
    },
    {
        label: 'GMT+11:00 Sakhalin',
        value: '+11:00 Sakhalin'
    },
    {
        label: 'GMT+11:00 Srednekolymsk',
        value: '+11:00 Srednekolymsk'
    },
    {
        label: 'GMT+11:00 Bougainville',
        value: '+11:00 Bougainville'
    },
    {
        label: 'GMT+11:00 Efate',
        value: '+11:00 Efate'
    },
    {
        label: 'GMT+11:00 Guadalcanal',
        value: '+11:00 Guadalcanal'
    },
    {
        label: 'GMT+11:00 Kosrae',
        value: '+11:00 Kosrae'
    },
    {
        label: 'GMT+11:00 Norfolk',
        value: '+11:00 Norfolk'
    },
    {
        label: 'GMT+11:00 Noumea',
        value: '+11:00 Noumea'
    },
    {
        label: 'GMT+11:00 Pohnpei',
        value: '+11:00 Pohnpei'
    },
    {
        label: 'GMT+11:00 Ponape',
        value: '+11:00 Ponape'
    },
    {
        label: 'GMT+12:00 McMurdo',
        value: '+12:00 McMurdo'
    },
    {
        label: 'GMT+12:00 Anadyr',
        value: '+12:00 Anadyr'
    },
    {
        label: 'GMT+12:00 Kamchatka',
        value: '+12:00 Kamchatka'
    },
    {
        label: 'GMT+12:00 Auckland',
        value: '+12:00 Auckland'
    },
    {
        label: 'GMT+12:00 Fiji',
        value: '+12:00 Fiji'
    },
    {
        label: 'GMT+12:00 Funafuti',
        value: '+12:00 Funafuti'
    },
    {
        label: 'GMT+12:00 Kwajalein',
        value: '+12:00 Kwajalein'
    },
    {
        label: 'GMT+12:00 Majuro',
        value: '+12:00 Majuro'
    },
    {
        label: 'GMT+12:00 Nauru',
        value: '+12:00 Nauru'
    },
    {
        label: 'GMT+12:00 Tarawa',
        value: '+12:00 Tarawa'
    },
    {
        label: 'GMT+12:00 Wake',
        value: '+12:00 Wake'
    },
    {
        label: 'GMT+12:00 Wallis',
        value: '+12:00 Wallis'
    },
    {
        label: 'GMT+12:45 Chatham',
        value: '+12:45 Chatham'
    },
    {
        label: 'GMT+13:00 Apia',
        value: '+13:00 Apia'
    },
    {
        label: 'GMT+13:00 Enderbury',
        value: '+13:00 Enderbury'
    },
    {
        label: 'GMT+13:00 Fakaofo',
        value: '+13:00 Fakaofo'
    },
    {
        label: 'GMT+13:00 Tongatapu',
        value: '+13:00 Tongatapu'
    },
    {
        label: 'GMT+14:00 Kiritimati',
        value: '+14:00 Kiritimati'
    }
];

export default null;

export {
    defaultTimeValue,
    getManageDefaultTimeZone,
    timeZoneOptions
};
