import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    onClosed: _ctx.closed,
    "align-center": true,
    "close-on-click-modal": false
  }, {
    header: _withCtx(() => [
      _renderSlot(_ctx.$slots, "header")
    ]),
    footer: _withCtx(() => [
      _renderSlot(_ctx.$slots, "footer")
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["onClosed"]))
}